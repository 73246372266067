import React from 'react'
import styled from "styled-components"

const Footer = () => {
  return (
    <FooterSection>
      <FooterWrapper>
        <FooterP>
          &copy; {new Date().getFullYear()} Cignals All Rights Reserved.
        </FooterP>
      </FooterWrapper> 
    </FooterSection>
  )
}

export default Footer

const FooterSection = styled.div``
const FooterWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 16px;
  text-align: center;
`
const FooterP = styled.p`
  font-size: 13px;
`
